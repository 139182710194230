import {Component, Input} from "@angular/core";
import {Const} from "@const/Const";
import {BaseFormDialog1} from "@dialogs/base-form-dlg1";
import {Log} from "@services/log";
import {now} from "underscore";
import {DateUtil} from "@services/date-utils";

@Component({
  selector: "[assign-carrier]",
  templateUrl: "./index.html",
  styleUrls: ['../../../../dialogs/dialogs.scss', './index.scss']
})
export class AssignCarrier extends BaseFormDialog1 {
  protected formGroupDeclaration: FormGroupDeclaration = {
    currentCarrier: {label: 'Current assigned', required: true, readOnly: true, submitReadOnly: true},
    newCarrier: {label: "Are you sure to assign new carrier?", required: true, readOnly: true, submitReadOnly: true},
  };

  get isCreateNew(): boolean {
    return true;
  }

  get btnSubmitLabel(): string {
    return 'Assign Carrier';
  }

  public isError = false;
  public isLoading = false;

  @Input() job;
  @Input() bestOffer;
  @Input() cost;

  constructor() {
    super();
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchDataSameRouteAlreadyAssigned();
  }

  get isAssignedCarrier() {
    return !!this.job.assignedCarrier;
  }

  get infoAssignedCarrier() {
    if (!this.isAssignedCarrier) return;
    let timeAssigned = DateUtil.format(this.job.assignedCarrier.update.when, 'YYYY/MM/DD HH:mm:ss');
    return {timeAssigned}
  }

  onBtnSave(): void {
    this.assignCarrier(this.bestOffer);
  }

  protected onUpdateSuccess(resp) {
    this.updateSuccess(resp);
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }

  private assignCarrier(data, oldCarrierAssigned = null) {
    if (!this.job) {
      return Log.e("jobId is required");
    }
    this.setEnableFormGroup(false);
    this.startProgress();

    const assignedCarrier = this.job?.assignedCarrier;
    if (assignedCarrier && !oldCarrierAssigned) {
      oldCarrierAssigned = {
        oldCarrier: {
          id: assignedCarrier.carrierId,
          name: this.job?.carrier?.basicInfo?.name ?? 'N/A'
        },
        carrierSalesRep: this.job?.carrierSalesRep,
      }
    }

    const params = {
      jobId: this.job.id,
      carrierId: data.carrierId,
      cost: this.cost,
      oldCarrierAssigned
    };
    this.api.POST(`${Const.APIURI_CARRIER_BIDS}/accept-bid`, params).subscribe(
      (resp) => {
        Log.d("assignCarrier done ", resp);
        this.onUpdateSuccess(resp);
        this.stopProgress();
      },
      (err) => {
        if (err?.data?.oldCarrier) {
          return this.modalService.confirm({
            nzTitle: `Route ${this.job.code} was just assigned to a new carrier`,
            nzContent: err?.message,
            nzClosable: false,
            nzMaskClosable: false,
            nzCentered: true,
            nzOkText: 'Yes',
            nzOnOk: () => {
              this.assignCarrier(data, {
                oldCarrier: err?.data?.oldCarrier,
                carrierSalesRep: err?.data?.carrierSalesRep
              });
            },
            nzCancelText: 'No',
            nzOnCancel:() => {
              this.stopProgress();
              this.setEnableFormGroup(true);
            }
          });
        } else {
          this.isError = true;
          this.showErr(err);
          this.stopProgress();
          this.closeDialog();
          this.setEnableFormGroup(true);
        }
        
      }
    );
  }

  protected readonly now = now;

  public listOfJobSameDayAndEquipment: any[] = [];
  private fetchDataSameRouteAlreadyAssigned() {
    const carrierId = this.bestOffer?.carrier?.id;
    const jobId = this.job?.id;
    if (!carrierId || !jobId) {
      return;
    }
    this.listOfJobSameDayAndEquipment = [];
    const url = Const.APIV2(`${Const.APIURI_CARRIERS}/${carrierId}/get-same-route-already-assigned`)
    this.api.POST(url, { jobId }).subscribe(
      (resp) => {
        if (resp?.data?.list_data?.length) {
          this.listOfJobSameDayAndEquipment = resp.data.list_data;
        }
      },
      (err) => {}
    );
  }

  getRouterLinkByJobId(jobId) {
    return `${Const.routeAdminDispatchList}/${jobId}`;
  }
}
