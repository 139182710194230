<ng-template [nzModalTitle]>
  <div class="component-header center-vertical flex-space-between">
    <div class="center-vertical">
      <div class="modal-title">Filters</div>
    </div>
    <div class="btn-action right30">
      <button nz-button (click)="onBtnReset()" class="right15" [nzLoading]="loading">Reset</button>
      <button nz-button nzType="primary" (click)="onBtnSave()" [nzLoading]="loading" nzGhost>
        <i nz-icon nzType="filter" nzTheme="fill"></i> {{ data?.id ? 'Update' : 'Save' }} Filter
      </button>
      <ng-container *ngIf="!isEdit">
        <nz-divider nzType="vertical"></nz-divider>
        <button nz-button nzType="primary" (click)="onBtnApply()" [nzLoading]="loading">Apply</button>
      </ng-container>
    </div>
  </div>
</ng-template>
<div>
  <form [formGroup]="formInput" nz-form>
    <ng-container *ngFor="let key of ['name']">
      <div class="form-label" >Filter's name<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <div nz-row [nzGutter]="{md:32}" class="form-group">
        <div nz-col nzSm="6" nzMd="6" class="form-item" [ngClass]="key">
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input filter's name!">
              <input nz-input [formControlName]="key" [placeholder]="getPlaceHolder(key)" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </ng-container>
    <div class="separator h bottom10"></div>

    <div class="form-label">Customers, Carriers, Pickup/Delivery States</div>
    <div nz-row [nzGutter]="{md:32}" class="form-group">
      <div nz-col nzSm="6" nzMd="6" *ngFor="let key of ['clientId']" class="form-item" [ngClass]="key" #customerFilter>
        <div>
          <div class="form-label-v2">{{getLabel(key)}}</div>
          <div class="flex" style="min-width: 100px;">
            <div>
              <nz-select formControlName="clientFilterType"  (ngModelChange)="onChange('clientFilterType', $event)" style="width: 100%; min-width: 80px">
                <nz-option nzLabel="Include" nzValue="include"></nz-option>
                <nz-option nzLabel="Exclude" nzValue="exclude"></nz-option>
              </nz-select>
            </div>
            <select-by-searching
              [version]="2"
              nzMode="multiple"
              [(listData)]="listClients.data"
              [formControlName]="key"
              [placeholder]="getPlaceHolder(key)"
              dropdownClassName="dispatch-filter-customer"
              (ngModelChange)="onChange($event, key)"
              style="width: 100%;"
            ></select-by-searching>
          </div>
        </div>
      </div>

      <div nz-col nzSm="6" nzMd="6" *ngFor="let key of ['carrierId']" class="form-item" [ngClass]="key">
        <div>
          <div class="form-label-v2">{{getLabel(key)}}</div>
          <nz-select
            nzBackdrop="true"
            nzAllowClear
            nzShowSearch
            [formControlName]="key"
            [nzLoading]="listCarriers.loading"
            [nzPlaceHolder]="getPlaceHolder(key)"
            nzDropdownClassName="dispatch-filter-carrier"
            style="width: 100%"
            (ngModelChange)="onChangeCarrier($event, key); onChange($event, key)"
          >
            <nz-option-group *ngIf="isTabAll" nzLabel="Filter by assignment status">
              <nz-option nzValue="no" nzLabel="Not assigned yet"></nz-option>
              <nz-option nzValue="yes" nzLabel="Already assigned"></nz-option>
            </nz-option-group>
            <nz-option-group nzLabel="Filter by specific carrier">
              <nz-option
                nzCustomContent
                *ngFor="let item of listCarriers.data"
                [nzValue]="item.id"
                [nzLabel]="getCarrierNameWithMCandDOT(item)"
              >
                <div>{{item?.basicInfo?.name ?? ''}}</div>
                <div *ngIf="item?.basicInfo?.mc" class="sub-info">MC: {{getCarrierMC(item)}}</div>
                <div *ngIf="item?.basicInfo?.dot" class="sub-info">DOT: {{getCarrierDOT(item)}}</div>
              </nz-option>
            </nz-option-group>
          </nz-select>
        </div>
      </div>

      <div class="form-item" [ngClass]="key" *ngFor="let key of ['pickupState']" nz-col nzSm="6" nzMd="6">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <ng-container
          [ngTemplateOutlet]="tplSelectState"
          [ngTemplateOutletContext]="{key, form: formInput}"
        ></ng-container>
      </div>

      <div class="form-item" [ngClass]="key" *ngFor="let key of ['dropoffState']" nz-col nzSm="6" nzMd="6">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <ng-container
          [ngTemplateOutlet]="tplSelectState"
          [ngTemplateOutletContext]="{key, form: formInput}"
        ></ng-container>
      </div>
    </div>
    <div class="separator h bottom10"></div>
    <div class="form-label">
      Date Range
      <nz-radio-group [(ngModel)]="dateRangePreset" [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangePreset($event)">
        <label nz-radio [nzValue]="3" class="left20">Next 3 days</label>
        <label nz-radio [nzValue]="7">Next 7 days</label>
      </nz-radio-group>
    </div>
    <div nz-row [nzGutter]="{md:32}" class="form-group">
      <div *ngFor="let key of ['fromDate']" nz-col nzSm="12" nzMd="8" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-input-group>
          <nz-date-picker
            style="width: 65%"
            [formControlName]="key"
            nzFormat="yyyy-MM-dd"
            (ngModelChange)="onChange($event, key)"
          >
          </nz-date-picker>
          <ng-container *ngFor="let key1 of ['fromTimezone']">
            <nz-select nzBackdrop="true" style="width: 35%"
            [nzPlaceHolder]="getPlaceHolder(key1)" [formControlName]="key1" 
            nzDropdownClassName="timezone"
            (ngModelChange)="onChange($event, key1)"
            >
              <nz-option *ngFor="let timezone of listTimezones" [nzLabel]="timezone" [nzValue]="timezone"></nz-option>
           </nz-select>
          </ng-container>
        </nz-input-group>
      </div>
      <div *ngFor="let key of ['toDate']" nz-col nzSm="12" nzMd="8" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-input-group>
          <nz-date-picker
            style="width: 65%"
            [formControlName]="key"
            nzFormat="yyyy-MM-dd"
            (ngModelChange)="onChange($event, key)"
          >
          </nz-date-picker>
          <ng-container *ngFor="let key1 of ['toTimezone']">
            <nz-select nzBackdrop="true" style="width: 35%;"
              [nzPlaceHolder]="getPlaceHolder(key1)" [formControlName]="key1" 
              nzDropdownClassName="timezone"
              (ngModelChange)="onChange($event, key1)"
              >
              <nz-option *ngFor="let timezone of listTimezones" [nzLabel]="timezone" [nzValue]="timezone"></nz-option>
            </nz-select>
          </ng-container>
        </nz-input-group>
      </div>

      <div *ngFor="let key of ['timeToPickup']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-select formControlName="timeToPickup" style="width: 100%">
          <ng-container *ngFor="let item of listTimeToPickups">
            <nz-option [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
          </ng-container>
        </nz-select>
      </div>
    </div>
    <div class="separator h bottom10"></div>
    <div class="form-label" style="display: flex;">Equipment, Shipment Type</div>
    <div nz-row [nzGutter]="{md:32}" class="form-group">
      <div *ngFor="let key of ['vehicleType']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-form-item>
          <nz-form-control>
            <div vehicle-selector [value]="getVehicleType()" (valueChange)="onChange($event, key)" [quoting]="false" [withOptions]="false"></div>
            <!-- <app-form-equipments [formControlName]="key" (ngModelChange)="onChange($event, key)"></app-form-equipments> -->
          </nz-form-control>
        </nz-form-item>
      </div>
      <div *ngFor="let key of ['shipmentType']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-select
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          style="width: 100%"
          [formControlName]="key"
          [nzPlaceHolder]="getPlaceHolder(key)"
          (ngModelChange)="onChange($event, key)"
        >
          <nz-option *ngFor="let item of listShipmentTypes" [nzValue]="item" [nzLabel]="item"> </nz-option>
        </nz-select>
      </div>
    </div>
    <div class="separator h bottom10"></div>
    <div class="form-label">Sales Rep</div>
    <div nz-row [nzGutter]="{md:32}" class="form-group">
      <div *ngFor="let key of ['carrierSaleRepId']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
          [formControlName]="key" [nzLoading]="listCarrierSales.loading"
          [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-carrier-sale-rep"
           style="width: 100%;" (ngModelChange)="onChange($event, key)">
          <nz-option-group nzLabel="Filter by specific carrier sales rep">
            <nz-option *ngFor="let item of listCarrierSales.data"
              [nzValue]="item.id" [nzLabel]="getFullName(item)">
            </nz-option>
          </nz-option-group>
        </nz-select>
      </div>
      <div *ngFor="let key of ['clientSaleRepId']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-select nzAllowClear nzShowSearch
          [formControlName]="key" [nzLoading]="listClientSales.loading"
          [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-carrier-sale-rep"
          style="width: 100%;" (ngModelChange)="onChange($event, key)">
          <nz-option-group nzLabel="Filter by specific carrier sales rep">
            <nz-option *ngFor="let item of listClientSales.data"
              [nzValue]="item._id" [nzLabel]="getFullName(item)">
            </nz-option>
          </nz-option-group>
        </nz-select>
      </div>
    </div>
  </form>
</div>
<ng-template #tplSelectState let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-select
      style="width: 100%"
      nzShowSearch
      nzAllowClear
      nzBackdrop="true"
      [nzPlaceHolder]="getPlaceHolder(key)"
      [formControlName]="key"
      nzDropdownClassName="dispatch-filter-state"
      (ngModelChange)="onChange($event, key)"
    >
      <nz-option-group *ngFor="let country of countriesStates" [nzLabel]="country.name">
        <nz-option
          *ngFor="let state of country.states"
          nzValue="{{country.code}}_{{state.code}}"
          [nzLabel]="getStateDesc(state)"
        ></nz-option>
      </nz-option-group>
    </nz-select>
  </ng-container>
</ng-template>
