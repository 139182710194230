<div class="filter-group">
  <select-filter-info-component #selectFilterInfo screen="carrier-sales" (onFilterChange)="onSelectFilter($event)" (onEdit)="onOpenSearch($event)"
    [defaultFilters]="defaultFilters"
  >
  </select-filter-info-component>
  <a (click)="onOpenSearch()" nz-button nzType="link" *ngIf="!currentFilterDesc">
    All Filters
  </a>
  <a (click)="onOpenSearch()" nz-button nzType="link" *ngIf="currentFilterDesc"
    nz-popover
    nzPopoverTitle="Current filter"
    [nzPopoverContent]="tplCurrentFilterDesc">
    All Filters
    <i nz-icon nzType="filter" nzTheme="fill"></i>
    <ng-template #tplCurrentFilterDesc>
      <div style="white-space: pre-wrap;">{{currentFilterDesc}}</div>
    </ng-template>
  </a>
</div>
