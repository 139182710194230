import { Component, Input, OnInit } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: "send-docs",
  templateUrl: "./send-docs.component.html",
  styleUrls: ["./send-docs.component.scss"],
})
export class SendDocsComponent
  extends BaseFormDialog1
  implements OnInit
{
  protected formGroupDeclaration: FormGroupDeclaration = {
    sendLoadTender: {label: 'Load Tender', type: 'boolean', initialValue: false},
    sendBOL: {label: 'BOL', type: 'boolean', initialValue: false},
    contactName: { label: "Name" },
    contactEmail: { label: "Email", required: true },
    remark: { label: "Remark" },
  };
  constructor() {
    super();
  }

  @Input() job;
  public isError = false;
  public isLoading = false;
  public confirmData: any = {};
  public inConfirm: boolean = false;
  public lastSent: string | null;
  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.createFormInput();
    const basicInfo = this.job?.carrier?.basicInfo || {};
    let contacts = basicInfo?.contacts || [];
    let contact = contacts[0] || {};
    this.setItemValue("contactName", basicInfo.name || "Carrier");
    this.setItemValue("contactEmail", contact.email);
    this.getItemByKey("contactEmail").disable();

    this.api
      .POST(`${Const.APIURI_JOBS}/get_latest_send_file_email`, {
        jobId: this.job?._id,
        type: "loadTender",
      })
      .subscribe(
        (resp) => {
          let lastSentInfo = resp?.data?.insert;
          if (!lastSentInfo) return;
          this.lastSent = DateUtil.dateToString(lastSentInfo.when, "M/DD/YYYY");
        },
        (err) => {}
      );
  }

  onEditEmail() {
    if (this.getItemByKey("contactEmail").enabled)
      this.getItemByKey("contactEmail").disable();
    else this.getItemByKey("contactEmail").enable();
  }

  onBtnSave() {
    let json: any = this.getFormData_JSON(true);
    if (!this.inConfirm) {
      this.confirmData = json;
      this.inConfirm = true;
      return;
    }

    let data = {
      contacts: [
        {
          name: json.contactName,
          email: json.contactEmail,
        },
      ],
      remark: json.remark,
      jobId: this.job?._id
    };

    let isSendEmailLoadTender = this.getItemValue('sendLoadTender');
    let isSendEmailBOL = this.getItemValue('sendBOL');
    if (isSendEmailLoadTender) {
      // data['fileId'] = this.job?.loadTender?._id;
      data['type'] = "loadTender";
      this.startProgress();
      this.isLoading = true;
      this.api.POST(`${Const.APIURI_JOBS}/send_file_email`, data).subscribe(
        (resp) => {
          this.stopProgress();
          this.isLoading = false;
          this.showSuccess("Load Tender email has been sent successfully.");
        },
        (err) => {
          this.showErr(err);
          this.stopProgress();
          this.isLoading = false;
        }
      );
    }
    if (isSendEmailBOL) {
      // data['fileId'] = this.job?.bol?._id;
      data['type'] = "bol";
      this.startProgress();
      this.isLoading = true;
      this.api.POST(`${Const.APIURI_JOBS}/send_file_email`, data).subscribe(
        (resp) => {
          this.stopProgress();
          this.isLoading = false;
          this.showSuccess("BOL email has been sent successfully.");
        },
        (err) => {
          this.showErr(err);
          this.stopProgress();
          this.isLoading = false;
        }
      );
    }
    this.closeDialog();
  }
  onBtnCancel() {
    //back to form
    if (this.inConfirm) {
      this.inConfirm = false;
      return;
    }
    //close dialog
    this.closeDialog();
  }
}
