import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { FilterInfoModal } from "./filter-modal";
import { ModalHelper } from "@wearewarp/ng-antd";
import { FilterInfo } from "@wearewarp/types/data-model";
import { SelectFilterInfoComponent } from "@app/admin/components/filter-info/select-filter-info";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "filter-info-select",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class FilterInfoSelectComponent extends BaseComponent {
  @Output() onFilterChange = new EventEmitter();

  currentFilterDesc = "";
  loading = false;
  defaultFilters: Array<Partial<FilterInfo>> = [
    {
      id: "default-1",
      data: {
        name: "Walmart Only",
        filterData: { clientId: ['01H22NK3MBXBDCW6AZDWFZ09V0'], clientFilterType: "include" }
      }
    },
    {
      id: "default-2",
      data: {
        name: "Exclude Walmart",
        filterData: { clientId: ['01H22NK3MBXBDCW6AZDWFZ09V0'], clientFilterType: "exclude" }
      }
    }
  ];

  @ViewChild("selectFilterInfo") selectFilterInfo: SelectFilterInfoComponent;

  constructor(private modalHelper: ModalHelper, private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onOpenSearch(filterInfo?: FilterInfo) {
    const modalRef = this.modalHelper.openForm(FilterInfoModal, {
      nzComponentParams: {
        data: filterInfo || this.getFilterFromQuery(),
        onSave: (data, desc) => {
          if (data.filterId) {
            const { filterId, ...dataUpdate } = data;
            this.selectFilterInfo.update(data.filterId, dataUpdate).subscribe((res) => {
              this.selectFilterInfo.loadData(res.data?.id);
            }, (err) => {
              this.showErr(err);
            })
          } else {
            this.selectFilterInfo.create(data).subscribe((res) => {
              this.selectFilterInfo.loadData(res.data?.id);
            }, (err) => {
              this.showErr(err);
            })
          }
          modalRef.close();
        },
        onApply: (filterData, desc) => {
          this.onFilterChange.emit(filterData);
          this.currentFilterDesc = desc;
          this.selectFilterInfo.selectedId = null;
          modalRef.close();
        }
      },
      nzClassName: "send-message-form modal-xl",
      nzFooter: null,
    });
  }

  applyFilter(data: any) {
    this.onFilterChange.emit(data);
  }

  onSelectFilter(filter: FilterInfo) {
    this.applyFilter(filter?.data?.filterData || {});
  }

  getFilterFromQuery() {
    const filter = this.getQueryParam("filter");
    if (typeof filter === "string") {
      return JSON.parse(filter);
    }
    return filter;
  }
}
